<template>
  <MovieCard
    :movie
    :hide-link
    :render-above-the-fold
    :link-params
    class="relative"
  >
    <button
      ref="target"
      class="absolute right-2 top-2 z-20 flex size-9 items-center justify-center rounded-full bg-black/50"
      @click.stop.prevent="handleWatchListClick"
    >
      <InlineSvg
        :key="watchListIcon"
        class="fill-primary size-5"
        :name="watchListIcon"
      />
    </button>
  </MovieCard>
</template>

<script setup lang="ts">
import { useParentElement } from '@vueuse/core'
import type { Movie, LinkParam } from '#gql/default'

interface Props {
  movie: Movie
  hideLink?: boolean
  renderAboveTheFold?: boolean
  linkParams: LinkParam[]
}

withDefaults(defineProps<Props>(), {
  linkParams: () => [],
  hideLink: true,
  renderAboveTheFold: false,
})

const emit = defineEmits(['re-add', 'remove'])

const target = ref<HTMLElement | null>(null)
const recentlyRemovedFromWatchList = ref(false)

watch(recentlyRemovedFromWatchList, () => {
  const div = useParentElement(target)?.value?.querySelector('div')

  if (div) {
    div.classList.toggle('rounded-lg')
    div.classList.toggle('bg-gray-500')
    div.classList.toggle('opacity-50')
  }
})

const watchListIcon = computed(() =>
  recentlyRemovedFromWatchList.value
    ? SVG_ICON.BOOKMARK_OUTLINE
    : SVG_ICON.BOOKMARK_SOLID
)

function handleWatchListClick() {
  if (recentlyRemovedFromWatchList.value) {
    recentlyRemovedFromWatchList.value = false
    emit('re-add')
  } else {
    recentlyRemovedFromWatchList.value = true
    emit('remove')
  }
}

defineOptions({
  name: 'WatchListMovieCard',
})
</script>
